import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dropdown-options',
  standalone: true,
  templateUrl: './dropdown-options.component.html'
})
export class DropdownOptionsComponent {

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();

  constructor() {}
}
