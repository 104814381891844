import { FocusableOption } from "@angular/cdk/a11y";
import { Component, ElementRef, HostBinding } from "@angular/core";

@Component({
  selector: "app-dropdown-list-item",
  standalone: true,
  template: "<ng-content></ng-content>",
  host: {
    tabindex: "-1",
    role: "list-item"
  },
  styles: [
    `
           :host {
             display: block;
           }
         `
  ]
})
export class DropdownListItemComponent implements FocusableOption {
  @HostBinding("class") class = "dropdown-list-item";

  constructor(private element: ElementRef) {}

  focus() {
    this.element.nativeElement.focus();
  }
}
