import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription, merge } from 'rxjs';

const DROPDOWN_MAX_HEIGHT: number = 251.804;

@Directive({
    selector: '[dropdownTriggerFor]',
    standalone: true,
    host: {
        '(click)': 'toggleDropdown()',
        '(focus)': 'toggleDropdown()'
    }
})
export class DropdownTriggerForDirective implements OnInit, OnDestroy {
    private isDropdownOpen = false;
    private overlayRef: OverlayRef;
    private dropdownClosingActionsSub = Subscription.EMPTY;
    private subscribed: boolean;

    @Input('dropdownTriggerFor') public dropdownPanel: DropdownPanel;
    @Input('disabledDropdown') public disabledDropdown: boolean;
    @Output() backdropClick: EventEmitter<void>;

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef
    ) {
        this.subscribed = false;
        this.backdropClick = new EventEmitter<void>();
    }

    ngOnInit(): void {
        (<HTMLInputElement>this.elementRef.nativeElement.children.namedItem('dropdown-input')).addEventListener('keydown', (event) => {
            if (!this.isDropdownOpen && event.key.length === 1) {
                this.openDropdown();
            }
            if (this.isDropdownOpen && event.key === 'Enter') {
                this.destroyDropdown();
            }
        });
    }

    toggleDropdown(): void {
        this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
    }

    openDropdown(): void {
        if (this.disabledDropdown) {
            return;
        }

        this.isDropdownOpen = true;
        
        const rectBottom: number = this.elementRef.nativeElement.getBoundingClientRect().bottom;
        const hasSpace: boolean = DROPDOWN_MAX_HEIGHT <= (window.innerHeight - rectBottom);
        // TODO: It can be improved by calculating the real size of the dropdown (taking into account the number of items), it can be less than DROPDOWN_MAX_HEIGHT.
        // This can help for other improvements: https://github.com/angular/components/blob/main/src/material/select/select.ts - https://javascript.info/coordinates

        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            width: this.elementRef.nativeElement.offsetWidth - 1,
            maxHeight: `${DROPDOWN_MAX_HEIGHT}px`,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            panelClass: 'cdk-dropdown',
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: hasSpace ? 'bottom' : 'top',
                        overlayX: 'start',
                        overlayY: hasSpace ? 'top' : 'bottom',
                        offsetY: 0
                    }
                ])
        });

        const templatePortal = new TemplatePortal(
            this.dropdownPanel.templateRef,
            this.viewContainerRef
        );
        this.overlayRef.attach(templatePortal);

        if (!this.subscribed) {
            this.subscribed = true;
            document.getElementById("dropdown-list").addEventListener('keydown', (event) => {
                if (this.isDropdownOpen && event.key === 'Enter') {
                    this.destroyDropdown();
                }
            });
        }

        this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(
            () => this.destroyDropdown()
        );
    }

    private dropdownClosingActions(): Observable<MouseEvent | void> {
        const backdropClick$ = this.overlayRef.backdropClick();
        const detachment$ = this.overlayRef.detachments();
        const dropdownClosed = this.dropdownPanel.closed;

        return merge(backdropClick$, detachment$, dropdownClosed);
    }

    private destroyDropdown(): void {
        if (!this.overlayRef || !this.isDropdownOpen) {
            return;
        }

        (<HTMLInputElement>this.elementRef.nativeElement.children.namedItem('dropdown-input')).focus();
        this.dropdownClosingActionsSub.unsubscribe();
        this.isDropdownOpen = false;
        this.overlayRef.detach();
    }

    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }
}

export interface DropdownPanel {
    templateRef: TemplateRef<any>;
    readonly closed: EventEmitter<void>;
}