import { animate, style, transition, trigger } from "@angular/animations";
import { FocusKeyManager } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	NgZone,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
	forwardRef
} from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { rotateAnimation } from "src/app/core/animations";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { DropdownTriggerForDirective } from "../../directives/dropdown-trigger-for.directive";
import { SelectOption } from "../../interfaces/select-option.interface";
import { DropdownListItemComponent } from "./dropdown-list-item/dropdown-list-item.component";
import { DropdownOptionsComponent } from "./dropdown-options/dropdown-options.component";

@Component({
	selector: "app-dropdown",
	standalone: true,
	imports: [CommonModule, FormsModule, MatIconModule, MatProgressSpinnerModule, DropdownTriggerForDirective, DropdownListItemComponent, DropdownOptionsComponent],
	templateUrl: "./dropdown.component.html",
	styleUrls: ["./dropdown.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DropdownComponent),
			multi: true
		}
	],
	animations: [
		rotateAnimation,
		trigger("enterAnimation", [
			transition(":enter", [
				style({ transform: "scaleY(0)", transformOrigin: "top", opacity: 0 }),
				animate("70ms", style({ transform: "scaleY(1)", opacity: 1 }))
			]),
			transition(":leave", [
				style({
					transform: "scaleY(1)",
					transformOrigin: "top",
					opacity: 1
				}),
				animate("50ms", style({ transform: "scaleY(0)", opacity: 0 }))
			])
		])
	]
})
export class DropdownComponent implements OnInit, ControlValueAccessor, OnChanges, AfterViewInit {
	dropdownOpened: boolean;
	inputValue: string;
	selectedOption: SelectOption;
	previousValue: string;

	private initialPlaceholder: string;
	initialOptions: Array<SelectOption> = [];

	@Input() placeholder: string;
	@Input() search: boolean;
	@Input() fullWidth: boolean;
	@Input() label: string;
	@Input() required: boolean;
	@Input() disabled: boolean;
	@Input() options: Array<SelectOption>;
	@Input() defaultOption: SelectOption;
	@Input() formControl: UntypedFormControl;
	@Input() theme: string;
	@Input() showAll: boolean;
	@Input() cleanOnSelect: boolean;
	@Input() name: string;

	@Input() searching: boolean;
	@Input() dynamicOptions: boolean;

	@Output() onchange: EventEmitter<SelectOption> = new EventEmitter();
	@Output() onChangeSearch: EventEmitter<string> = new EventEmitter<string>();
	@Output() showAllFunction: any = new EventEmitter();

	@ViewChild('dropdown', { static: false }) dropdownOverlay: DropdownOptionsComponent;
	@ViewChildren(DropdownListItemComponent) items: QueryList<DropdownListItemComponent>;
	private keyManager: FocusKeyManager<DropdownListItemComponent>;

	constructor(
		private eRef: ElementRef,
		private zone: NgZone,
		private env: EnvironmentService
	) {
		this.dropdownOpened = false;
		this.cleanOnSelect = false;
	}

	@HostListener("keydown", ["$event"])
	onKeydown(event) {
		this.keyManager.onKeydown(event);
	}

	ngAfterViewInit() {
		this.keyManager = new FocusKeyManager(this.items).withWrap();
	}

	showAllButton() {
		this.showAllFunction.emit();
	}

	onChange: any = val => {
		if (this.previousValue !== val) {
			this.previousValue = val;
			this.onchange.emit(val);
		}

		// reset the dropdown to show the placeholder if no value is passed
		if (val === "") {
			this.inputValue = val;
			this.placeholder = this.initialPlaceholder;
		}
	};
	onTouch: any = () => { };

	set value(val) {
		this.inputValue = val;
		this.onChange(val);
		this.onTouch(val);
	}
	get value() {
		return this.selectedOption.value;
	}

	writeValue(value: any): void {
		if (this.options) {
			const selected = this.options.filter((opt) => {
				return opt.value === value;
			})[0];
			if (selected) {
				this.selectOption(selected);
				this.inputValue = selected.text;
			} else {
				this.inputValue = value;
			}
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	ngOnInit(): void {
		this.initialPlaceholder = this.placeholder;
		if (this.env.isBrowser) {
			this.zone.runOutsideAngular(() => {
				document.addEventListener("click", this.onDocumentClick.bind(this));
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		for (const propName in changes) {
			if (changes.hasOwnProperty(propName)) {
				if (propName === "options") {
					this.options = changes[propName].currentValue;
					this.initialOptions = changes[propName].currentValue;
					this.options?.forEach((item:any) => {
						if (item.value == this.inputValue) this.inputValue = item.text 
					});
				}
			}
		}
	}

	fullReset(): void {
		this.inputValue = "";
		this.options = this.initialOptions;
		this.placeholder = this.initialPlaceholder;
		this.selectedOption = null;
		this.previousValue = undefined;
	}

	private resetValues(): void {
		this.options = this.initialOptions;
		this.inputValue = this.selectedOption?.text;
		this.placeholder = this.selectedOption?.text || this.initialPlaceholder;
		this.onChange(this.selectedOption?.value || null);
	}

	private resetInput(): void {
		this.inputValue = "";
		this.placeholder = this.selectedOption?.text || this.placeholder;
	}

	onDocumentClick(e: MouseEvent): void {
		if (!this.eRef.nativeElement.contains(e.target) && this.dropdownOpened) {
			this.zone.run(() => {
				this.dropdownOpened = false;
				this.resetValues();
			});
		}
	}

	toggle(reset?: boolean): void {
		if (this.disabled) {
			return;
		}
		
		if (reset) {
			this.options = this.initialOptions;
			if (this.search) {
				this.inputValue = '';
			}
		}
		this.dropdownOpened = !this.dropdownOpened;
	}

	selectOption(option: SelectOption): void {
		if (option) {
			if (!this.cleanOnSelect) {
				this.selectedOption = option;
				this.inputValue = option.text;
			}
			this.dropdownOpened = false;
			this.onChange(option.value);
		}
	}

	handleSearch(): void {
		this.onChangeSearch.emit(this.inputValue);
		if (!this.dynamicOptions) {
			this.options = this.initialOptions.filter(o =>
				o.text.toLowerCase().includes(this.inputValue.toLowerCase())
			);
		}
		this.dropdownOpened = true
	}

	handleKeydown(event: KeyboardEvent, option): void {
		if (event.code === "Enter") {
			this.selectOption(option);
			this.dropdownOpened = false;
		}
		if (event.keyCode === 9) {
			this.dropdownOverlay.closed.emit();
			this.dropdownOpened = false;
		}
	}

	handleInputFocus(): void {
		if (this.disabled) return;
		if (this.search) {
			if (this.dropdownOpened) {
				this.resetInput();
			} else {
				this.dropdownOpened = true;
				this.options = this.initialOptions;
				this.resetInput();
			}
		}
	}
}
