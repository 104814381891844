<div class="inline-block w-48"
	[ngClass]="{
		'w-full': fullWidth,
		'opacity-50': disabled || formControl?.disabled
	}">
	<label>
		<span>
			{{ label }}
			@if (required) {
				<span>*</span>
			}
		</span>
		@if (showAll) {
			<a (click)="showAllButton()" class="text-xs float-right">Show all</a>
		}
	</label>
	<div class="relative">
		<div [ngClass]="{
				opened: dropdownOpened,
				'bg-gray-lighter border-gray-400': theme === 'gray'
			}" 
			class="dropdown-input flex justify-between text-sm mt-1 bg-white border rounded w-full text-right" 
			(click)="toggle(true)"
			[dropdownTriggerFor]="dropdown"
			[disabledDropdown]="disabled || formControl?.disabled">
			<input class="appearance-none focus:outline-none pl-4 py-2 rounded"
					(keydown)="handleKeydown($event, defaultOption)"
					[ngClass]="{
					'cursor-pointer': !search && !disabled && !formControl?.disabled,
					'bg-gray-lighter': theme === 'gray',
					'background-grey': disabled || formControl?.disabled
					}"
					type="text"
					id="dropdown-input"
					[disabled]="disabled || formControl?.disabled"
					[readOnly]="!search"
					[required]="required"
					[(ngModel)]="inputValue"
					(ngModelChange)="handleSearch()"
					[name]="name + '-dropdown'"
					autocomplete="off"
					[placeholder]="placeholder" />
			
			@if (!disabled && !formControl?.disabled && !searching) {
				<div class="pt-2 pr-1 absolute right-0" [style]="{ top: '-1px' }">
					<mat-icon [@rotate]="dropdownOpened">expand_more</mat-icon>
				</div>
			}

			@if (searching) {
				<div class="h-full flex items-center mr-1">
					<mat-spinner [diameter]="20"></mat-spinner>
				</div>
			}
		</div>

		<app-dropdown-options #dropdown>
			<div [@enterAnimation] [ngClass]="{ 'w-full': fullWidth }" id="dropdown-list">
				@if (defaultOption) {
					<app-dropdown-list-item
											(click)="selectOption(defaultOption)"
											(keydown)="handleKeydown($event, defaultOption)">
						<div class="dropdown-option">
							{{ defaultOption.text }}
						</div>
					</app-dropdown-list-item>	
				}
				
				@for (option of options; track $index) {
					<ng-container>
						@if (!option.hidden) {
							<app-dropdown-list-item
													(click)="selectOption(option)"
													(keydown)="handleKeydown($event, option)">
								<div class="dropdown-option break-words">
									{{ option.text }}
								</div>
							</app-dropdown-list-item>
						}
					</ng-container>
				}
			</div>
		</app-dropdown-options>
	</div>
</div>